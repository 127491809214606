// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Typography,
//   Button,
//   IconButton,
//   Collapse,
//   Paper,
//   Avatar,
// } from "@mui/material";
// import { Add, Remove, ExpandMore, ExpandLess } from "@mui/icons-material";
// import IconHandler from "./IconHandler";
// import foodIcon from "../images/icons/food.svg";
// import { useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { removeOrderItem, updateOrderItemCount } from "../Redux/store";
// // import nonVegIcon from "../images/icons/nonVegIcon.svg";
// import nonVegIcon from "../images/icons/ic_non_veg.svg";
// // import nonAlcholicIcon from "../images/icons/nonAlcoholicIcon.svg";
// import nonAlcholicIcon from "../images/icons/ic_non_alcoholic.svg";
// // import alcholicIcon from "../images/icons/AlcoholicIcon.svg";
// import alcholicIcon from "../images/icons/ic_alcoholic.svg";
// import vegIcon from "../images/icons/vegIcon.svg";
// import plusButton from "../images/icons/plus.svg";
// import minusButton from "../images/icons/minus.svg";
// import baverageIcon from "../images/icons/baverage.svg";
// import trackingIcon from "../images/icons/trackingIcon.svg";
// import S3ImageAmplify from "../config/S3ImageAmplify";
// import { navigateWithHostelParams } from "./NavigateWithHostelParams";


// const OrderSummary = ({ onClose, onQuantityChange, icon }) => {
//   const orderItems = useSelector((state) => state.food.orderItems); // Get order items from Redux
//   const dispatch = useDispatch(); // Get the dispatch function
//   const [expanded, setExpanded] = useState(false);
//   const [icons, setIcons] = useState(foodIcon);
//   const navigate = useNavigate();


//   console.log(orderItems);



//   function updateIcon(categoryName , categoryParentSubCategory) {
//     const isBeverage = categoryName.toLowerCase().includes('beverage');
//     const isExp = categoryParentSubCategory.toLowerCase().includes('exp');
//     setIcons(isBeverage ? baverageIcon
//       : isExp ? trackingIcon 
//       : foodIcon
//     );
//   }

//   // Calculate total amount (replace 0 with the actual price for each item)
//   const totalAmount = orderItems.reduce(
//     (total, item) => total + item.count * item.rate,
//     0
//   ); // Assuming item.rate is the base price

  
//   const handleQuantityChange = (item, newCount) => {
//     if (newCount < 0) return; // Prevent reducing below 0

//     if (newCount === 0) {
//       console.log("Count is now zero");
//        // If the count is zero, dispatch an action to remove the item
//     dispatch(removeOrderItem({ id: item.id, addOns: item.addOns }));
    
//     if (orderItems.length === 1) {
//       onClose(); // Close if no items left
//     }
//     // onClose();
//     } else {
//       // Calculate the new total price based on count and add-ons
//       const addOnTotal = (item?.addOns|| []).reduce(
//         (acc, addon) => acc + (addon.rate || 0),
//         0
//       );
//       console.log(addOnTotal);
//       const newTotalPrice = (item.rate + addOnTotal) * newCount;

//       // Dispatch action to update count and totalPrice
//       dispatch(
//         updateOrderItemCount({
//           ...item,
//           count: newCount,
//           totalPrice: newTotalPrice,
//         })
//       );
//     }
//   };
//   // Toggle expanded/collapsed view
//   const handleExpandToggle = () => setExpanded(!expanded);

//   return (
//     <Paper
//       sx={{
//         position: "fixed",
//         bottom: 0,
//         width: "100%",
//         padding: "16px",
//         zIndex: 1000,
//         borderTop: "1px solid #ccc",
//         boxShadow: "0px -1px 10px rgba(0, 0, 0, 0.1)",
//         paddingBottom: 6,
//       }}
//       elevation={3}
//     >
//       <Box
//         sx={{
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "space-between",
//           paddingRight: 4.5,
//           paddingLeft: 1.2,
//         }}
//       >
//         <Box sx={{ display: "flex", alignItems: "center" }}>
//           {/* <img
//             src={icon || foodIcon}
//             alt="Order Icon"
//             style={{ width: "40px", height: "40px", marginRight: "12px" }}
//           /> */}
//          <Box sx={{ display: "flex", position: "relative", width: "60px", height: "40px" }}>
//         {orderItems.slice(0, 3).map((item, index) => {
//           const useLocalImage = item.icon_name && item.icon_name.includes('ic');
          
//           return useLocalImage ? (
            
//             <IconHandler 
//   iconPath={item.icon_name} 
//   fallbackIcon={foodIcon} 
//   altText={item.name}
//   style={{
//         width: "40px",
//         height: "40px",
//         position: "absolute",
//         left: `${index * 15}px`,  // Adjust overlap
//         zIndex: orderItems.length - index,
//         borderRadius: "5px",
//       }}
// />

//           ) : (
//             <S3ImageAmplify
//               key={index}
//               imageKey={item.icon_name}  // S3 image key
//               grpUserId={item.user_id}   // User ID or other identifier for S3 fetch
//               altText={item.name}
//               style={{
//                 width: "40px",
//                 height: "40px",
//                 position: "absolute",
//                 left: `${index * 15}px`,
//                 zIndex: orderItems.length - index,
//                 borderRadius: "5px",
//               }}
//             />
//           );
//         })}
//       </Box>
//           <Typography variant="h6" sx={{ fontWeight: "bold" , marginLeft: "14px"}}>
//             {orderItems.length} items
//           </Typography>
//         </Box>
//         <Box sx={{ display: "flex", alignItems: "center" }}>
//           <IconButton onClick={handleExpandToggle}>
//             {expanded ? <ExpandLess /> : <ExpandMore />}
//           </IconButton>
//         </Box>
//         <Button
//           onClick={() => 
//             // navigate("/check-out", { state: { orderItems } })
//             navigateWithHostelParams(navigate, "/check-out", { state: { orderItems } })
//           }
//           variant="contained"
//           color="primary"
//           size="large"
//         >
//           Next
//         </Button>
//       </Box>

//       {/* Expanded view showing additional items */}
//       <Collapse in={expanded}>
//       <Box
//     sx={{
//       maxHeight: "300px", // Adjust the height as needed
//       overflowY: "auto", // Enable scrolling for vertical overflow
//     }}
//   >
//         {orderItems.map(
//           (item, index) => {
//             // console.log(item),
//             const itemIcon = 
//             item.sub_category_id  === 'exp'
//             ? trackingIcon : foodIcon ;

//                 // Check if the icon path includes 'ic' for using the local image
//         const useLocalImage = item.icon_name && item.icon_name.includes('ic');
//            return (
//               <Box
//                 key={index}
//                 sx={{
//                   display: "flex",
//                   alignItems: "center",
//                   justifyContent: "space-between",
//                   padding: "10px 16px",
//                   marginBottom: "15px",
//                 }}
//               >
//                 <Box
//                   sx={{
//                     display: "flex",
//                     alignItems: "center",
//                     flex: 1,
//                     maxWidth: "9%",
//                   }}
//                 >
//                     <Box sx={{ 
//               width: '50px', 
//               height: '40px', 
//               marginRight: '12px', 
//               display: 'flex', 
//               justifyContent: 'center', 
//               alignItems: 'center' 
//             }}>
//                   {useLocalImage ? (
//         // <Avatar
//         //   src={icon} // Use `item.icon` if provided, else fallback to default icon
//         //   variant="rounded"
//         //   style={{
//         //     width: "40px",
//         //     height: "40px",
//         //     marginRight: "12px",
//         //   }}
//         // />
//         <IconHandler 
//   iconPath={item.icon_name} 
//   fallbackIcon={foodIcon} 
//   altText={item.name}
//   style={{
//         width: "40px",
//         height: "40px",
//         marginRight: "12px",
//       }}
// />
//       ) : (
//         <S3ImageAmplify
//           imageKey={item.icon_name}
//           grpUserId={item.user_id}
//           altText={item.name}
//           style={{
//             width: "40px",
//             height: "40px",
//             marginRight: "12px",
//           }}
//         />
//       )}
//                    </Box>
//                   <Box sx={{width: "70%"}}>
//                     <Typography sx={{ textAlign: "left" }}>
//                     {/* {item?.name?.length > 10 ? `${item.name.slice(0, 10)}..` : item.name} */}
//                     {item.name}
//                     </Typography>
//                     <Typography variant="body2" color="textSecondary"  
//                     sx={{ display: 'inline',
//                       whiteSpace: 'pre-wrap', // Allows line breaks
//                       overflow: 'hidden',
//                       textOverflow: 'ellipsis', }}>
//                       {item?.addOns?.map((addOn) => addOn.name).join(", ")}
//                     </Typography>
//                     {item.item_type && (
//                     <Avatar
//                       src={
//                         item.item_type === "assets/icons/ic_veg.svg"
//                           ? vegIcon
//                           : item.item_type === "assets/icons/ic_non_veg.svg"
//                           ? nonVegIcon
//                           : item.item_type ===
//                             "assets/icons/ic_non_alcoholic.svg"
//                           ? nonAlcholicIcon
//                           : item.item_type === "assets/icons/ic_alcoholic.svg"
//                           ? alcholicIcon
//                           : ""
//                       }
//                       variant="rounded"
//                       sx={{ width: 20, height: 20 }}
//                     />
//                     )}
//                   </Box>
//                 </Box>
          
//                 <Box
//                   sx={{
//                      display:"block",
//                     // alignItems: "center",
//                     // justifyContent: "center",
//                     // flex: 0.4,
//                     // maxWidth: "9%",
//                     // marginLeft:7,
//                     mx: 2
//                   }}
//                 >
//                   <Box  sx={{display:'flex'}}>
//                   <IconButton
//                     onClick={() => handleQuantityChange(item, item.count - 1)}
//                   >
//                     <Avatar src={minusButton} sx={{ width: 24, height: 24 , borderRadius: 0 }} />
//                   </IconButton>
//                   <Typography
//                     sx={{
//                       // margin: "0 12px",
//                       // minWidth: "20px",
//                       padding:'8px',
//                       textAlign: "center",
//                     }}
//                   >
//                     {item.count}
//                   </Typography>
//                   <IconButton
//                     onClick={() => handleQuantityChange(item, item.count + 1)}
//                   >
//                     <Avatar src={plusButton} sx={{ width: 24, height: 24 ,borderRadius: 0  }} />
//                   </IconButton>
//                  </Box>
//                   <Box sx={{ width: 80, textAlign: 'right' }}>
//         <Typography variant="body2" fontWeight="medium">
//           ₹ {((item?.count || 0) * (item?.rate || 0)).toFixed(2)}
//         </Typography>
//       </Box>
//                 </Box>
        
              
//               </Box>
//             )
// }
//         )}
//         </Box>
//       </Collapse>
//     </Paper>
//   );
// };

// export default OrderSummary;





import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Collapse,
  Paper,
  Avatar,
} from "@mui/material";
import { Add, Remove, ExpandMore, ExpandLess } from "@mui/icons-material";
import IconHandler from "./IconHandler";
import foodIcon from "../images/icons/food.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeOrderItem, updateOrderItemCount } from "../Redux/store";
// import nonVegIcon from "../images/icons/nonVegIcon.svg";
import nonVegIcon from "../images/icons/ic_non_veg.svg";
// import nonAlcholicIcon from "../images/icons/nonAlcoholicIcon.svg";
import nonAlcholicIcon from "../images/icons/ic_non_alcoholic.svg";
// import alcholicIcon from "../images/icons/AlcoholicIcon.svg";
import alcholicIcon from "../images/icons/ic_alcoholic.svg";
import vegIcon from "../images/icons/vegIcon.svg";
import plusButton from "../images/icons/plus.svg";
import minusButton from "../images/icons/minus.svg";
import baverageIcon from "../images/icons/baverage.svg";
import trackingIcon from "../images/icons/trackingIcon.svg";
import S3ImageAmplify from "../config/S3ImageAmplify";
import { navigateWithHostelParams } from "./NavigateWithHostelParams";


const OrderSummary = ({ onClose, onQuantityChange, icon }) => {
  const orderItems = useSelector((state) => state.food.orderItems); // Get order items from Redux
  const dispatch = useDispatch(); // Get the dispatch function
  const [expanded, setExpanded] = useState(false);
  const [icons, setIcons] = useState(foodIcon);
  const navigate = useNavigate();


  console.log(orderItems);



  function updateIcon(categoryName , categoryParentSubCategory) {
    const isBeverage = categoryName.toLowerCase().includes('beverage');
    const isExp = categoryParentSubCategory.toLowerCase().includes('exp');
    setIcons(isBeverage ? baverageIcon
      : isExp ? trackingIcon 
      : foodIcon
    );
  }

  // Calculate total amount (replace 0 with the actual price for each item)
  const totalAmount = orderItems.reduce(
    (total, item) => total + item.count * item.rate,
    0
  ); // Assuming item.rate is the base price

  
  const handleQuantityChange = (item, newCount) => {
    if (newCount < 0) return; // Prevent reducing below 0

    if (newCount === 0) {
      console.log("Count is now zero");
       // If the count is zero, dispatch an action to remove the item
    dispatch(removeOrderItem({ id: item.id, addOns: item.addOns }));
    
    if (orderItems.length === 1) {
      onClose(); // Close if no items left
    }
    // onClose();
    } else {
      // Calculate the new total price based on count and add-ons
      const addOnTotal = (item?.addOns|| []).reduce(
        (acc, addon) => acc + (addon.rate || 0),
        0
      );
      console.log(addOnTotal);
      const newTotalPrice = (item.rate + addOnTotal) * newCount;

      // Dispatch action to update count and totalPrice
      dispatch(
        updateOrderItemCount({
          ...item,
          count: newCount,
          totalPrice: newTotalPrice,
        })
      );
    }
  };
  // Toggle expanded/collapsed view
  const handleExpandToggle = () => setExpanded(!expanded);

  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        padding: "16px",
        zIndex: 1000,
        borderTop: "1px solid #ccc",
        boxShadow: "0px -1px 10px rgba(0, 0, 0, 0.1)",
        paddingBottom: 6,
      }}
      elevation={3}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingRight: 4.5,
          paddingLeft: 1.2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {/* <img
            src={icon || foodIcon}
            alt="Order Icon"
            style={{ width: "40px", height: "40px", marginRight: "12px" }}
          /> */}
         <Box sx={{ display: "flex", position: "relative", width: "60px", height: "40px" }}>
        {orderItems.slice(0, 3).map((item, index) => {
          const useLocalImage = item.icon_name && item.icon_name.includes('ic');
          
          return useLocalImage ? (
            <IconHandler
              iconPath={item.icon_name}
              fallbackIcon={foodIcon}
              altText={item.name}
              style={{
                width: "40px",
                height: "40px",
                position: "absolute",
                left: `${index * 15}px`, // Adjust overlap
                zIndex: orderItems.length - index,
                borderRadius: "5px",
              }}
            />
          ) : (
            <S3ImageAmplify
              key={index}
              imageKey={item.icon_name} // S3 image key
              grpUserId={item.user_id} // User ID or other identifier for S3 fetch
              hostelId={item.hostel_id}
              altText={item.name}
              style={{
                width: "40px",
                height: "40px",
                position: "absolute",
                left: `${index * 15}px`,
                zIndex: orderItems.length - index,
                borderRadius: "5px",
              }}
            />
          );
        })}
      </Box>
          <Typography variant="h6" sx={{ fontWeight: "bold" , marginLeft: "14px"}}>
            {orderItems.length} items
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={handleExpandToggle}>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
        <Button
          onClick={() => 
            // navigate("/check-out", { state: { orderItems } })
            navigateWithHostelParams(navigate, "/check-out", { state: { orderItems } })
          }
          variant="contained"
          color="primary"
          size="large"
        >
          Next
        </Button>
      </Box>

      {/* Expanded view showing additional items */}
      <Collapse in={expanded}>
      <Box
    sx={{
      maxHeight: "300px", // Adjust the height as needed
      overflowY: "auto", // Enable scrolling for vertical overflow
    }}
  >
        {orderItems.map(
          (item, index) => {
            // console.log(item),
            const itemIcon = 
            item.sub_category_id  === 'exp'
            ? trackingIcon : foodIcon ;

                // Check if the icon path includes 'ic' for using the local image
        const useLocalImage = item.icon_name && item.icon_name.includes('ic');
           return (
             <Box
               key={index}
               sx={{
                 display: "flex",
                 alignItems: "center",
                 justifyContent: "space-between",
                 padding: "10px 16px",
                 marginBottom: "15px",
               }}
             >
               <Box
                 sx={{
                   display: "flex",
                   alignItems: "center",
                   flex: 1,
                   maxWidth: "9%",
                 }}
               >
                 <Box
                   sx={{
                     width: "50px",
                     height: "40px",
                     marginRight: "12px",
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                   }}
                 >
                   {useLocalImage ? (
                     // <Avatar
                     //   src={icon} // Use `item.icon` if provided, else fallback to default icon
                     //   variant="rounded"
                     //   style={{
                     //     width: "40px",
                     //     height: "40px",
                     //     marginRight: "12px",
                     //   }}
                     // />
                     <IconHandler
                       iconPath={item.icon_name}
                       fallbackIcon={foodIcon}
                       altText={item.name}
                       style={{
                         width: "40px",
                         height: "40px",
                         marginRight: "12px",
                       }}
                     />
                   ) : (
                     <S3ImageAmplify
                       imageKey={item.icon_name}
                       grpUserId={item.user_id}
                       hostelId={item.hostel_id}
                       altText={item.name}
                       style={{
                         width: "40px",
                         height: "40px",
                         marginRight: "12px",
                       }}
                     />
                   )}
                 </Box>
                 <Box sx={{ width: "70%" }}>
                   <Typography sx={{ textAlign: "left" }}>
                     {/* {item?.name?.length > 10 ? `${item.name.slice(0, 10)}..` : item.name} */}
                     {item.name}
                   </Typography>
                   <Typography
                     variant="body2"
                     color="textSecondary"
                     sx={{
                       display: "inline",
                       whiteSpace: "pre-wrap", // Allows line breaks
                       overflow: "hidden",
                       textOverflow: "ellipsis",
                     }}
                   >
                     {item?.addOns?.map((addOn) => addOn.name).join(", ")}
                   </Typography>
                   {item.item_type && (
                     <Avatar
                       src={
                         item.item_type === "assets/icons/ic_veg.svg"
                           ? vegIcon
                           : item.item_type === "assets/icons/ic_non_veg.svg"
                           ? nonVegIcon
                           : item.item_type ===
                             "assets/icons/ic_non_alcoholic.svg"
                           ? nonAlcholicIcon
                           : item.item_type === "assets/icons/ic_alcoholic.svg"
                           ? alcholicIcon
                           : ""
                       }
                       variant="rounded"
                       sx={{ width: 20, height: 20 }}
                     />
                   )}
                 </Box>
               </Box>

               <Box
                 sx={{
                   display: "block",
                   // alignItems: "center",
                   // justifyContent: "center",
                   // flex: 0.4,
                   // maxWidth: "9%",
                   // marginLeft:7,
                   mx: 2,
                 }}
               >
                 <Box sx={{ display: "flex" }}>
                   <IconButton
                     onClick={() => handleQuantityChange(item, item.count - 1)}
                   >
                     <Avatar
                       src={minusButton}
                       sx={{ width: 24, height: 24, borderRadius: 0 }}
                     />
                   </IconButton>
                   <Typography
                     sx={{
                       // margin: "0 12px",
                       // minWidth: "20px",
                       padding: "8px",
                       textAlign: "center",
                     }}
                   >
                     {item.count}
                   </Typography>
                   <IconButton
                     onClick={() => handleQuantityChange(item, item.count + 1)}
                   >
                     <Avatar
                       src={plusButton}
                       sx={{ width: 24, height: 24, borderRadius: 0 }}
                     />
                   </IconButton>
                 </Box>
                 <Box sx={{ width: 80, textAlign: "right" }}>
                   <Typography variant="body2" fontWeight="medium">
                     {/* ₹ {((item?.count || 0) * (item?.rate || 0)).toFixed(2) } */}
                     {(
                       item?.count *
                       (item?.rate +
                         (item.addOns?.reduce(
                           (acc, addon) => acc + (addon?.rate || 0),
                           0
                         ) || 0))
                     ).toFixed(2)}
                   </Typography>
                 </Box>
               </Box>
             </Box>
           );
}
        )}
        </Box>
      </Collapse>
    </Paper>
  );
};

export default OrderSummary;
