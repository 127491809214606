import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import expIcon from "../../images/icons/exp_icon.svg";
import {
  Box,
  Grid,
  Typography,
  Avatar,
  IconButton,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { clearOrderItems, setCategories } from "../../Redux/store";
import { getHostelData } from "../../api/services/FetchNodeService";
import { navigateWithHostelParams } from "../../components/NavigateWithHostelParams";
import FoodLoader from "../../Loader/loading";
import OrderSummary from "../../components/OrderSummary";
import RenderNoResults from "../../components/RenderNoResults";

// Import your icons
import categoryFoodIcon from "../../images/icons/categoryFoodIcon.svg";
import backButton from "../../images/icons/backIcon.svg";
import fnbIcon from "../../images/icons/fnb_icon.svg";
import SearchImage from "../../images/icons/search.svg";
import foodIcon from "../../images/icons/food.svg";
import S3ImageAmplify from "../../config/S3ImageAmplify";
import IconHandler from "../../components/IconHandler";
import { SearchDialog } from "./components/SearchDialog";
import { useHostelContext } from "../../context/HostelContext";

const CategoryList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const orderItems = useSelector((state) => state.food.orderItems);
  const reduxCategories = useSelector((state) => state.food.categories);
  const { hostelId } = useHostelContext();
  const { label, categories: locationCategories } = location.state || {};
  const originalLabel = label || localStorage.getItem("LABEL");

  const [loading, setLoading] = useState(false);
  const [icon, setIcons] = useState(foodIcon);
  const [items, setItems] = useState([]);
  const [searchResults, setSearchResults] = useState({
    categories: [],
    items: [],
  });
  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false);
  const [categories, setCategoriesState] = useState(
    locationCategories || reduxCategories
  );
  const [isOrderSummaryVisible, setIsOrderSummaryVisible] = useState(
    orderItems.length > 0
  );
  const [searchLoading, setSearchLoading] = useState(false);
  const [parentSubCat, setParentSubCat] = useState(
    originalLabel === "Food & Beverage" ? "fnb" : "exp"
  );

  const categoryIcons = {
    Continental: categoryFoodIcon,
    "Thali's": categoryFoodIcon,
    Tandoor: categoryFoodIcon,
    Breakfast: categoryFoodIcon,
    "Hot & Cold Beverages": categoryFoodIcon,
    "Food & Beverage": fnbIcon,
    "Experiences & Merchandise": expIcon,
  };

  const handleCloseOrderSummary = () => {
    setIsOrderSummaryVisible(false);
  };

  const fetchItems = async () => {
    try {
      const apiEndpoint = `account/item-hostel/${hostelId}/${[parentSubCat]}`;

      const response = await getHostelData(apiEndpoint, navigate);
      if (response?.data?.length) {
        setItems(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchCategories = async () => {
    setLoading(true);
    try {
      if (hostelId) {
        console.log(
          `account/sub-category/hostel/${hostelId}/parent_sub_cat/${parentSubCat}`
        );
        const response = await getHostelData(
          `account/sub-category/hostel/${hostelId}/parent_sub_cat/${parentSubCat}`,
          navigate
        );
        console.log("response", response);
        const filteredCategories = response.data.filter(
          (category) =>
            category.total_inventory > 0 && category.available === true
        );
        console.log("filteredCategories", filteredCategories);
        dispatch(setCategories(filteredCategories));
        setCategoriesState(filteredCategories);
        setCategories(filteredCategories);
      }
    } catch (error) {
      console.error("Failed to fetch categories:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!categories || categories.length === 0) {
      fetchCategories();
    }
  }, [categories.length]);

  const handleCategoryClick = async (category) => {
    setLoading(true);
    try {
      const data = await fetchCategoryItem(
        category.id,
        category.parent_sub_category
      );
      setIsSearchDialogOpen(false);
      navigateWithHostelParams(navigate, "/food-item", {
        state: { datas: data, category: category },
      });
    } catch (error) {
      console.error("Error fetching category items:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleCategoryClickByItem = async (item) => {
    setLoading(true);
    try {
      const data = await fetchCategoryItem(
        item.category_id,
        item.sub_category_id
      );
      const category = await getHostelData(
        `account/account-category/${item.category_id}`,
        navigate
      );
      setIsSearchDialogOpen(false);
      navigateWithHostelParams(navigate, "/food-item", {
        state: { datas: data, category: category },
      });
    } catch (error) {
      console.error("Error fetching category items:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCategoryItem = async (categoryId, parent_sub_category) => {
    try {
      let response;
      if (parent_sub_category === "fnb") {
        response = await getHostelData(
          `account/fnb-item/sub-category/${categoryId}`,
          navigate
        );
      } else if (parent_sub_category === "exp") {
        response = await getHostelData(
          `account/exp-item/sub-category/${categoryId}`,
          navigate
        );
      }
      if (response && response.data) {
        return response.data; // Return the fetched data
      } else {
        throw new Error("No data returned from the API");
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
      throw error; // Rethrow the error to handle it in the click event
    }
  };


  const handleBackClick = () => {
    if (orderItems.length > 0) {
      Swal.fire({
        title: "Are you sure?",
        text: "Your cart have items. Do you want to discard your cart?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, discard",
        cancelButtonText: "No, keep items",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(clearOrderItems()); // Clear the order items
          navigate(-1); // Navigate back
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // navigate(-1); // Navigate back without clearing
        }
      });
    } else {
      navigate(-1); // Navigate back directly if cart is empty
    }
  };

const sortedCategories = [...categories].sort((a, b) =>
  a.name.localeCompare(b.name)
);


  return (
    <>
      <Box
        sx={{
          backgroundColor: "#f5f5f5",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#ffffff",
            boxShadow: "0px -2px 5px rgba(0, 0, 0, 0.4)",
            zIndex: 1000,
            paddingTop: 2,
            position: "fixed",
            top: 0,
            width: "100%",
          }}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{
              marginBottom: "14px",
              paddingRight: "24px",
              paddingLeft: "24px",
            }}
          >
            <IconButton onClick={handleBackClick}>
              <Avatar
                src={backButton}
                alt="Back"
                sx={{ width: 24, height: 24 }}
              />
            </IconButton>
            <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: 18 }}>
              {originalLabel}
            </Typography>
            <IconButton onClick={() => setIsSearchDialogOpen(true)}>
              <Avatar
                src={SearchImage}
                alt="Search"
                sx={{ width: 24, height: 24 }}
              />
            </IconButton>
          </Grid>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            overflow: "auto",
            marginTop: "72px",
            marginBottom: "37px",
          }}
        >
          {loading ? (
            <FoodLoader loading={loading} />
          ) : categories?.length > 0 ? (
            <Grid
              container
              spacing={2}
              sx={{
                padding: "24px",
                paddingBottom: orderItems.length ? "72px" : "24px",
              }}
            >
              {
              categories
                .slice().sort((a, b) => a.name.localeCompare(b.name)) // Sort categories alphabetically by name
                .map((category) => { 
                  const categoryImage =
                    category.icon_image &&
                    category.icon_image.includes("image");
                  return (
                    <Grid item xs={6} key={category.id}>
                      <Box
                        onClick={() => handleCategoryClick(category)}
                        sx={{
                          backgroundColor: "#fff",
                          padding: "16px",
                          borderRadius: "12px",
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                          cursor: "pointer",
                          textAlign: "center",
                          minHeight: "150px",
                          margin: 1,
                        }}
                      >
                        {categoryImage ? (
                          <S3ImageAmplify
                            imageKey={category.icon_image}
                            grpUserId={category.user_id}
                            hostelId={category.hostel_id}
                            altText="Category"
                            style={{
                              width: 56,
                              height: 56,
                              margin: "0 auto 8px auto",
                            }}
                          />
                        ) : (
                          <Avatar
                            src={categoryIcons[originalLabel]}
                            sx={{
                              width: 56,
                              height: 56,
                              margin: "0 auto 8px auto",
                            }}
                            variant="rounded"
                          />
                        )}

                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: "bold",
                            fontSize: 16,
                            margin: 1,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            height: "48px",
                            textAlign: "center",
                          }}
                        >
                          {category.name}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: "#6c757d", fontSize: 14 }}
                        >
                          Total Items: {category.total_inventory}
                        </Typography>
                      </Box>
                    </Grid>
                  );})}
            </Grid>
          ) : (
            <RenderNoResults name="Category" />
          )}
        </Box>
      </Box>

      <SearchDialog
        items={items}
        categories={categories}
        open={isSearchDialogOpen}
        onClose={() => setIsSearchDialogOpen(false)}
        setIsSearchDialogOpen={setIsSearchDialogOpen}
        loading={loading}
        handleCategoryClick={handleCategoryClick}
        handleCategoryClickByItem={handleCategoryClickByItem}
        categoryIcons={categoryIcons}
        searchResults={searchResults}
        searchLoading={searchLoading}
        originalLabel={originalLabel}
      />

      {isOrderSummaryVisible && (
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "#fff",
            boxShadow: "0px -4px 10px rgba(0, 0, 0, 0.1)",
            borderTop: "1px solid #e0e0e0",
            zIndex: 1000,
            marginTop: "auto",
            margin: 0,
            padding: 0,
          }}
        >
          <OrderSummary
            orderItems={orderItems}
            onClose={handleCloseOrderSummary}
            icon={icon}
          />
        </Box>
      )}
    </>
  );
};

export default CategoryList;
