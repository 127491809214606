import {useNavigate} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearOrderItems, setCategories } from '../../Redux/store';
import { useState, useEffect } from 'react';
import { getHostelData } from '../../api/services/FetchNodeService';
import { Button, CircularProgress , Grid } from '@mui/material';
import Swal from 'sweetalert2';
import { navigateWithHostelParams } from '../../components/NavigateWithHostelParams';
import rightIcon from "../../images/icons/rightIcon.svg";

export const CategoryButton = ({ icon, label, parentSubCat }) => {
//   console.log(icon, label, parentSubCat);
    const navigate = useNavigate();
    const dispatch = useDispatch(); // Get the dispatch function
  
    const orderItems = useSelector((state) => state.food.orderItems);
  
    const [loading, setLoading] = useState(false); // Loading state
  
    const handleClick = async () => {
  
      
  
     if (orderItems.length > 0 && orderItems[0].sub_category_id !== parentSubCat) {
      const result = await Swal.fire({
        title: 'Change Category?',
        text: 'You have items in your cart from another category. Changing the category will clear your cart. Do you want to proceed?',
        icon: 'warning',
        confirmButtonText: 'Clear Cart',
        showCancelButton: true, // Enable cancel button
        cancelButtonText: 'Cancel',
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
  
      if (!result.isConfirmed) {
        // If the user cancels, do nothing and return early
        return;
      }
  
      dispatch(clearOrderItems()); // Clear the order items
      Swal.fire('Cart Cleared', 'Your cart has been cleared.', 'success');
    }
  
  
  
   
      // dispatch(clearOrderItems()); // Clear the order items
      setLoading(true); // Start loading
      try {
        const hostel_id = localStorage.getItem('hostel_id'); // Fetch hostel_id from local storage
        const hostelName = localStorage.getItem('hostel_name');
        if (hostel_id) {
          const response = await getHostelData(`account/sub-category/hostel/${hostel_id}/parent_sub_cat/${parentSubCat}` , navigate);
          // Handle the response data as needed
          const filteredCategories = response.data.filter(category => 
           category.total_inventory > 0 && category.available === true
          );
        

       // Dispatch the action to store categories in Redux
         dispatch(setCategories(filteredCategories));
         const categories = filteredCategories;
          // Navigate to the specified route after fetching data
          // navigate(`/categories/${parentSubCat}` , { state: {  label ,categories  } });
  
          navigateWithHostelParams(navigate, `/categories/${parentSubCat}` , {    hostelName,
            hostel_id, state:{label ,categories}  });
            localStorage.setItem('LABEL' , label);
            localStorage.setItem('parentSubCat' , parentSubCat);
  
        } else {
          console.error('Hostel ID not found in local storage.');
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
      finally {
        setLoading(false); // Stop loading
      }
    };
  
    return (
      <Button
        variant="contained"
        // startIcon={icon}
        startIcon={loading ? <CircularProgress size={24} /> : icon}
        onClick={handleClick} // Trigger the API call and navigation on click
        disabled={loading} // Disable button while loading
        sx={{
          width: '100%',
          justifyContent: 'flex-start',
          padding: '16px',
          marginBottom: '16px',
          backgroundColor: '#fff',
          color: '#000',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          borderRadius: '12px',
          textTransform: 'none',
          fontWeight: 'bold',
          fontSize: '16px'
        }}
      >
        <Grid container  alignItems="center">
          <Grid item>
            {label} 
          </Grid>
          <Grid item sx={{marginLeft:1 , marginBottom:'-4px'}}>
            {/* <img src={rightIcon} alt="Right Icon" /> */}
            {!loading && <img src={rightIcon} alt="Right Icon" />}
          </Grid>
        </Grid>
      </Button>
    );
  };
