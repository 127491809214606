


import React, { useEffect, useState } from 'react';
import { getFile } from './getFile';
import { Height } from '@mui/icons-material';
import { Avatar, CircularProgress } from '@mui/material';


const S3ImageAmplify = ({ imageKey, grpUserId, hostelId, altText , style }) => {
  console.log('imageKey', imageKey);
  const [imageUrl, setImageUrl] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchImage() {
      try {
        const url = await getFile(imageKey, grpUserId , hostelId);
        console.log(url);
        if (url) {
          setImageUrl(url);
        } else {
          setError("Error: Failed to generate signed URL");
        }
      } catch (err) {
        console.error(err);
        setError("Error fetching image");
      }
    }

    fetchImage();
  }, [imageKey, grpUserId]);

  // if (error) {
  //   return <div>Error: {error}</div>;
  // }

   // Get initials from altText
   const getInitials = (text) => {
    console.log(text);
    return text
      ? text
          .split(' ')[0]        // Get only the first word
          .replace(/[^a-zA-Z]/g, '') // Remove special characters
          .charAt(0)             // Take the first character
          .toUpperCase()         // Convert to uppercase
      : '';
  };
  

  if (error) {
    // Display initials in case of an error
    return (
      <Avatar sx={{ ...style, bgcolor: 'error.main' }} variant="rounded">
        {getInitials(altText)}
      </Avatar>
    );
  }
  console.log(imageUrl);

  return imageUrl ? (
    <Avatar  
    //  sx={{ width: 76, height: 76, marginBottom: '12px', margin: '0 auto' ,   
    //   marginTop: '25px',
    //   // marginBottom:10
    //  }} 
    sx={style}
     variant="rounded" 
      src={imageUrl} alt={altText} />
  ) : (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <CircularProgress />
  </div>
  );
};




export default S3ImageAmplify;






