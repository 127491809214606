
import { getUrl } from '@aws-amplify/storage';


export const getFile = async (key, grpUserId , hostelId) => {

  const isNew = (key) => {
    return key.startsWith("image-");
  };

  const extractModulePath = (key) => {
    try {
      const parts = key.split("-"); // Split by '-'
      const length = parts.length;

      // Extract module, year, month, and day from the key
      const module = parts[length - 4];
      const year = parts[length - 3];
      const monthName = parts[length - 2];
      const day = parts[length - 1];

      return `${module}/${year}/${monthName}/${day}/${key}`;
    } catch (e) {
      console.error("Error extracting module path:", e);
      return "Invalid key format";
    }
  };

   console.log(grpUserId);
  if (!key || key.trim() === "") {
    return null;
  }

  // Check if the key contains 'googleusercontent', return key directly
  if (key.includes('googleusercontent')) {
    return key;
  } else {
    try {
      // Construct the path based on whether the key is new or old
      const path = isNew(key)
        ? `${encodeURIComponent(
            grpUserId
          )}/${hostelId}/${extractModulePath(key)}`
        : `${encodeURIComponent(grpUserId)}/${key}`;

        console.log("path new",  path);
        console.log("path old" , `${grpUserId}/${key}`);

      // Fetch the signed URL from S3 using Amplify Storage
      const result = await getUrl({
        // key: `${grpUserId}/${key}`,
        key: path,
        options: {
          // accessLevel: 'public', // Changed from 'level' to 'accessLevel'
          accessLevel: `public`, // Changed from 'level' to 'accessLevel'
          expires: 604800, // Expiration in seconds (7 days = 604800 seconds)
          useAccelerateEndpoint: true, // Optional: to use S3 accelerate endpoint
        },
      });
      console.log(result);
      return result.url; // Return the URL from the result object
    } catch (error) {
      console.error("Error getting file:", error);
      return null;
    }
  }
};